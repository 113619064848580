@tailwind base;
@tailwind components;
@tailwind utilities;

:root,
#root,
html,
body {
  height: 100vh;
  @apply antialiased;
}

html {
  scroll-behavior: smooth;
}

.markdown pre {
  word-break: break-all;
  white-space: break-spaces;
}

.markdown ul {
  @apply list-disc list-inside;
}

.markdown ol {
  @apply list-decimal list-inside;
  margin-bottom: 10px;
  margin-left: 10px;
}

.markdown ul ul,
.markdown ul ol,
.markdown ol ul,
.markdown ol ol {
  @apply pl-5 mt-2;
}

.markdown ul > li > p:first-child,
.markdown ol > li > p:first-child {
  @apply contents;
}

.markdown ul > li > p:first-child ~ *,
.markdown ol > li > p:first-child ~ * {
  @apply mt-2;
}

.markdown ul > li,
.markdown ol > li {
  @apply mb-2;
}

.markdown blockquote,
.markdown dd,
.markdown dl,
.markdown figure,
.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6,
.markdown hr,
.markdown p,
.markdown pre {
  margin: revert;
  margin-top: 0;
  @apply mb-2;
}

.markdown code {
  background-color: rgba(175, 184, 193, 0.2);
}

table {
  @apply text-left;
}

table th,
table td {
  @apply align-top pb-2;
}

.active svg,
.active span {
  @apply text-brand-secondary;
}

@layer utilities {
  .pb-safe {
    padding-bottom: max(env(safe-area-inset-bottom), 0.75rem);
  }
}
